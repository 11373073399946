import yup from 'modules/validation';

export type Model = {
  debugTextInput: string;
  debugTextInputEmpty: string;
  debugTextInputDisabled: string;
  debugTextInputDisabledEmpty: string;
  debugTextInputReadOnly: string;
  debugTextInputEmptyReadOnly: string;
};

export const defaultValues: Model = {
  debugTextInput: 'Prefilled Value',
  debugTextInputEmpty: '',
  debugTextInputDisabled: 'Prefilled Value',
  debugTextInputDisabledEmpty: '',
  debugTextInputReadOnly: 'Prefilled Value',
  debugTextInputEmptyReadOnly: '',
};

export const schema = yup.object().shape({
  debugTextInput: yup.string().required(),
  debugTextInputEmpty: yup.string().required(),
  debugTextInputDisabled: yup.string(),
  debugTextInputDisabledEmpty: yup.string(),
  debugTextInputReadOnly: yup.string(),
  debugTextInputEmptyReadOnly: yup.string(),
});
